.admin-card-order.MuiCard-root {
  max-height: 85px;
  background-color: #ffff !important;
  padding: 20px !important;
}

.admin-card-revenue.MuiCard-root {
  border-radius: 10px !important;
  height: 430px !important;
  background-color: #ffff !important;
  padding: 10px !important;
}

.admin-card-sales.MuiCard-root {
  border-radius: 10px !important;
  height: 430px !important;
  background-color: #ffff !important;
  padding: 10px !important;
}

.selectLimit {
  height: 36px;
}

.cusProductName {
  background: #f3f5f7;
  text-align: center;
  border-radius: 4px;
  width: 100px;
  padding: 10px;
  p {
    font-size: 10px;
    font-weight: 500;
    font-family: cursive !important;
  }
}

.cusAreaChart {
  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    font-weight: 400;
    font-size: 12px;
    color: #b7babf;
    background: transparent !important;
    border-bottom: none !important;
    margin-left: 5px !important;
    padding: 6px !important;
  }

  .apexcharts-tooltip-series-group {
    .apexcharts-tooltip-marker {
      display: none !important;
    }

    .apexcharts-tooltip-text {
      .apexcharts-tooltip-y-group {
        .apexcharts-tooltip-text-y-label {
          color: #101828;
          font-size: 12px;
        }

        .apexcharts-tooltip-text-y-value {
          font-weight: 700;
          font-size: 14px;
          color: #101828;
          margin: 0px !important;
        }
      }
    }
  }

  .apexcharts-xaxistooltip {
    display: none !important;
  }
}
.cusBtnMonth {
  .activeMonth {
    margin-right: 7px;
    border-color: #235d5e;
    color: #ffff;
    background-color: #235d5e;
  }
  .cusMonthBtn {
    margin-right: 7px;
    border-color: #235d5e;
    color: #000000;
    background-color: transparent;
  }
}
