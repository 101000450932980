@import "variables";

html,
body {
  height: 100%;
  font-size: 14px;
  -ms-touch-action: none;
  touch-action: none;
  -ms-content-zooming: none;
  margin: 0;
  padding: 0;
}

body.theme-light {
  background: #f5f4f9;
}

body.theme-dark {
  background: #121212;
}

// PR
