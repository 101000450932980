@import "../../../../scss/variables";
@import "../../../../scss/mixins/mixins";

:root {
  .MuiPaper-root.MuiDrawer-paper {
    background-color: $sidebarBg;
    color: $textColor;
  }
}

.active {
  background-color: rgba(0, 0, 0, 0.04);
}
.nxusLogo {
  height: 65px;
  width: 70px;
}

.headerLogoContainer {
  display: flex;
  margin: 10px 15px;
  cursor: pointer;

  .logoText {
    margin-left: 7px;
    font-weight: 700;
    font-size: 25.7758px;
    color: #325c5d;
  }
}
