@import "../variables";

.admin-card {
  padding: 10px;

  .admin-card-header {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 20px;
    .MuiTypography-root {
      padding-top: 10px;
      padding-left: 5px;
      padding-bottom: 10px;
    }
  }
  .MuiCardActions-root {
    margin-top: 20px;
    padding: 10px;
    justify-content: flex-end;
    border-radius: 4px;
  }
}

.MuiCardContent-root {
  padding: 0px !important;
  padding-bottom: 100px !important;
}
