.productsContainer {
  .busSelect {
    position: relative;
    width: 23%;
    margin-left: 20px;
    .busSelectCloseIcon {
      position: absolute;
      cursor: pointer;
      right: 14px;
      bottom: 15px;
    }
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 1px;
  }

  .cusForm .MuiFormLabel-root {
    top: -8px;
  }
}
