@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "variables";
@import "mixins/mixins";
@import "reset";
@import "components/card";
@import "components/input";
@import "components/modal";
@import "components/buttons";
@import "components/table";

* {
  font-family: "poppins", sans-serif;
  @extend .style-1;
}

#root {
  padding: 0;
  height: 100%;
}
.icon {
  background-size: 30px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
}

:root {
  .MuiTypography-h6 {
    color: $primary;
  }
  .css-9mgopn-MuiDivider-root {
    border-color: $primary;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: $primary;
  }
}

.style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.style-1::-webkit-scrollbar {
  width: 10px;
}

.style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #8baadf;
}
