@import "mixins/mixins";

$primary: #007bff;
$sidebarBg: #333;
$danger: red;
$shadowColorBlack: rgba(0, 0, 0, 0.25);
$shadowColorGrey: rgba(213, 213, 217, 0.25);
$shadowColorGreyLight: rgba(195, 195, 195, 0.36);
$disableColorBg: rgba(211, 211, 211, 0.3);
$activeColorBg: #e0ebfe;
$borderColor: rgba(165, 165, 165, 0.3);
$textColor: #ffffff;
$modalColorBg: #ffffff;
$tableRowColorBg: #ffffff;
$dividerColor: #5290ff;
$buttonPrimary: #007bff;
$input: #e0ebfe;
$tableHeaderBg: #333;

$themes: (
  light: (
    primary: #7366ff,
    secondary: #ffffff,
    bgColor: #f5f4f9,
    txtColor: #333,
    barBg: #ffffff,
    border: #cacaca,
    buttonDefault: #ffffff,
    shadowColorBlack: rgba(0, 0, 0, 0.25),
    inputBg: #e0ebfe,
    inputlabel: #535353,
    tableBorder: rgba(165, 165, 165, 0.3),
  ),
  dark: (
    primary: #588ded,
    secondary: #19181b,
    bgColor: #121212,
    txtColor: #ffffff,
    barBg: #161a26,
    border: #393939,
    buttonDefault: #000000,
    shadowColorBlack: #222326,
    inputBg: #222833,
    inputlabel: #656565,
    tableBorder: #27354e,
  ),
);
