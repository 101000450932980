@import "../variables";

.ag-header-cell {
  color: $textColor;
}

.ag-header-icon {
  color: $textColor !important;
}

.ag-header-cell:hover {
  background-color: #333 !important;
}

.ag-header {
  background-color: $tableHeaderBg !important;
}

.ag-theme-alpine .ag-icon-menu {
  color: $textColor !important;
}

.ag-root-wrapper.ag-layout-normal {
  border-radius: 6px;
}

.MuiDataGrid-root {
  @include themify($themes) {
    color: themed("txtColor");
  }
  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeaders {
    @include themify($themes) {
      border-color: themed("tableBorder");
    }
  }
  .MuiDataGrid-columnSeparator--sideRight {
    display: none;
  }
  .MuiDataGrid-columnHeaders {
    background-color: $tableHeaderBg;
    color: $textColor;
    .MuiDataGrid-columnSeparator {
      display: none;
    }
  }

  .MuiDataGrid-virtualScrollerContent {
    background-color: $tableRowColorBg;
  }
  .css-c1gd0l-MuiDataGrid-overlay {
    background-color: transparent;
  }
}
:root {
  .css-1p5q5e5-MuiStack-root {
    align-items: flex-end;
    margin-top: 15px;
  }
}
