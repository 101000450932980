@import "../../../scss/components/card";

.modal-mui {
  width: 860px !important;
  height: 570px;
  overflow: auto;
}

.image-upload-container-view {
  width: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7366ff;
  font-size: 16px;
}

.image-view {
  background: #ffffff;
  z-index: 1;

  img {
    width: 197px;
    height: 128px;
    object-fit: cover;
    border: 2px dashed #b8b8b8;
  }
}
.fullscreen-exit-btn {
  svg {
    g {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.fullscreen-group--expanded {
  .fullscreen-image {
    background-color: #000;
    img {
      max-width: 100%;
      height: 100%;
      width: initial;
      border: none;
    }
  }
}
.images {
  display: flex;
  li {
    margin-top: 100px;
  }
}

.signatures-img {
  object-fit: fill;
  height: 75px;
  width: 140px;
}

@media (max-width: 768px) {
  .modal-mui {
    width: calc(100% - 50px) !important;
  }
  .image-view {
    background: #ffffff;
    z-index: 1;

    img {
      width: 170px;
    }
  }
}

.no-filter-icon .ag-header-cell-menu-button {
  display: none;
}

.card-field {
  font-size: 14px !important;
  color: black;
}

.card-field-12 {
  font-size: 12px !important;
  color: var(--gray-600, #585d69);
}

.card-field-14 {
  font-size: 14px !important;
  color: var(--gray-900, #101828);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-table {
  display: none;
  margin-top: 10px;
  padding-bottom: 40px;
}

.table-card {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .modal-mui {
    width: 600px !important;
    height: 100px;
    overflow: auto;
  }

  .card-table {
    display: block;
  }

  .table-card {
    display: none;
  }
}
.restBtnMain {
  .resetbtn {
    box-shadow: none;
    text-transform: capitalize;
    background-color: #efefef;
    color: #000000;
    padding: 6px 15px !important;
    font-size: 14px;
  }
  .resetbtn:hover {
    background-color: #efefef;
  }
}
